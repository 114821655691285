import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IQuestion, IAnswer, IOption, IContest} from "data/types/contests";
import {MarketTypeEnum, QuestionStatusEnum, AnswerStatusEnum, QuestionTypeEnum} from "data/enums";
import {first, get, includes} from "lodash";
import type {IQuestionsController} from "views/pages/questions/questions.controller";
import type {IUserStatsStore} from "data/stores/user_stats/user_stats.store";

interface IParams {
	question: IQuestion;
	contestId: number;
}

export interface IQuestionController extends ViewController<IParams> {
	i18n: ILocalizationStore;
	get contest(): IContest | undefined;
	get answer(): IAnswer | undefined;

	get answerOption(): IOption | undefined;
	get correctAnswer(): IOption | undefined;

	get questionString(): MarketTypeEnum | null | undefined;

	get questionMsg(): string | null;

	get isTiebreaker(): boolean;

	get isLocked(): boolean;

	get isCorrect(): boolean;

	get isInCorrect(): boolean;

	get selectedOptionPoints(): number;

	addAnswer(optionId: number): void;

	addValueAnswer(value: string): void;

	isSelected(optionId: number): boolean;

	getAnswerStatus(optionId: number): AnswerStatusEnum | null;

	getIsCorrectAnswerButNotSelect(optionId: number): boolean;

	formatOption(option?: IOption): string;
}

@injectable()
export class QuestionController implements IQuestionController {
	@observable private _question: IQuestion | null = null;
	@observable private _contestId: number = 0;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.QuestionsController) private _questionsController: IQuestionsController,
		@inject(Bindings.UserStatsStore) private _userStatsStore: IUserStatsStore
	) {
		makeAutoObservable(this);
	}

	get contest() {
		return this._contestStore.getByID(this._contestId);
	}

	get questionString() {
		return this._question?.marketType;
	}

	get questionMsg() {
		const msg = get(this._question, "message", "");
		return msg ? ` (${msg})` : null;
	}

	get isLocked(): boolean {
		return this._question?.status !== QuestionStatusEnum.OPEN;
	}

	get correctAnswer() {
		if (this.answer?.correctValue) {
			return this._question?.options.find(
				//(option) => option.id === this.answer?.correctValue
				(option) => includes(this.answer?.correctValue, option.id)
			);
		}
		return this.answerOption;
	}

	get isCorrect(): boolean {
		// return !!this.answer && this.answer.value === this.answer.correctValue;
		let correctVal;
		if (!Array.isArray(this.answer?.correctValue)) {
			correctVal = this.answer?.correctValue;
			return !!this.answer && correctVal === this.answer.value;
		} else {
			correctVal = this.answer?.correctValue;
			return !!this.answer && !!correctVal?.includes(this.answer.value);
		}
	}

	get isInCorrect(): boolean {
		let correctVal;
		const questionStatus = get(this._question, "status", QuestionStatusEnum.COMPLETE);
		const hasStarted = QuestionStatusEnum.LOCKED === questionStatus;
		const questionAnswer = get(this._question, "answer", null);
		const noAnswer = questionStatus && questionAnswer === null;
		if (hasStarted) {
			return false;
		}
		if (noAnswer) {
			return true;
		}
		if (!Array.isArray(this.answer?.correctValue)) {
			correctVal = this.answer?.correctValue;
			return !!this.answer && correctVal !== this.answer.value;
		} else {
			correctVal = this.answer?.correctValue;
			return !!this.answer && !correctVal?.includes(this.answer.value);
		}
	}

	get isTiebreaker(): boolean {
		return this._question?.type === QuestionTypeEnum.TIEBREAKER;
	}

	get questionID() {
		return this._question?.id ?? 0;
	}

	get questionStatus() {
		return get(this._question, "status");
	}

	get answer() {
		return this._answersStore.getByID(this.questionID);
	}

	get answerOption() {
		return this._question?.options.find((option) => option.id === this.answer?.value);
	}

	get selectedOptionPoints() {
		return this.answerOption?.points || 0;
	}

	private get overUnderQuestion() {
		const option = first(this._question?.options);

		if (option) {
			return this.i18n.t(
				"question.type.over_under",
				`Will there be over or under ${option.handicap} goals?`,
				{
					handicap: option.handicap,
				}
			);
		}

		return "";
	}

	private get handicapQuestion() {
		if (!this._question) {
			return "-";
		}

		const favoredTeam = this._question.options.find((option) => option.handicap < 0);
		const handicap =
			this._question.options.find((option) => option.handicap > 0)?.handicap || "-";

		if (favoredTeam) {
			return this.i18n.t(
				"question.type.handicap",
				`Will ${favoredTeam.name} win by more than ${handicap} goals?`,
				{
					handicap: handicap,
					favored_team: favoredTeam.name,
				}
			);
		}

		return "";
	}

	init(param: IParams) {
		this._question = param.question;
		this._contestId = param.contestId;
	}

	async addAnswer(optionId: number): Promise<void> {
		if (this.questionID && !this.isLocked) {
			this._answersStore.add(this.questionID, optionId, this._contestId);
			await this._userStatsStore.fetch(this.i18n.lang);
			this._questionsController.toNext();
		}
	}

	addValueAnswer(value: string): void {
		if (this.questionID) {
			const intValue = parseInt(value, 10);
			if (intValue < 0 || intValue > 99) {
				return;
			}
			if (value || intValue === 0) {
				this._answersStore.add(this.questionID, intValue, this._contestId);
			} else {
				this._answersStore.remove(this.questionID);
			}
		}
	}

	isSelected(optionId: number): boolean {
		return this._answersStore.has(this.questionID, optionId);
	}

	getAnswerStatus(optionId: number): AnswerStatusEnum | null {
		if (this.isSelected(optionId)) {
			if (this.answer?.correctValue) {
				if (includes(this.answer.correctValue, this.answer?.value)) {
					return AnswerStatusEnum.CORRECT;
				}

				if (this.answer.correctValue && !this.answer?.value) {
					return AnswerStatusEnum.MISSED;
				}

				return AnswerStatusEnum.IN_CORRECT;
			}

			if (this.isLocked) {
				return AnswerStatusEnum.LOCKED;
			}
		}

		return null;
	}

	getIsCorrectAnswerButNotSelect(optionId: number): boolean {
		return Boolean(
			this.answer?.correctValue &&
				!this.isSelected(optionId) &&
				includes(this.answer.correctValue, optionId)
		);
	}

	formatOption(option?: IOption): string {
		if (!option) {
			return "";
		}
		if (this._question?.marketType === MarketTypeEnum.HANDICAP) {
			const prefix = option.handicap > 0 ? "+" : "";

			return `${option.name} ${prefix}${option.handicap}`;
		}
		if (this._question?.marketType === MarketTypeEnum.OVER_UNDER) {
			return `${option.name} ${option.handicap}`;
		}

		return option.name;
	}
}
