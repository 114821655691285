export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	AnswersApiProvider: Symbol("AnswersApiProvider"),
	DashboardApiProvider: Symbol("DashboardApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	CompetitionsStore: Symbol("CompetitionsStore"),
	UserStore: Symbol("UserStore"),
	AnswersStore: Symbol("AnswersStore"),
	DashboardStore: Symbol("DashboardStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	LocalizationController: Symbol("LocalizationController"),
	StaticContentStore: Symbol("StaticContentStore"),
	AuthController: Symbol("AuthController"),
	TermsController: Symbol("TermsController"),
	BackDoorController: Symbol("BackDoorController"),
	RegistrationController: Symbol("RegistrationController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	NotificationBarStore: Symbol("NotificationBarStore"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	WeekToggleController: Symbol("WeekToggleController"),
	BootstrapController: Symbol("BootstrapController"),
	HeaderController: Symbol("HeaderController"),
	ContestController: Symbol("ContestController"),
	QuestionsController: Symbol("QuestionsController"),
	QuestionController: Symbol("QuestionController"),
	ContestStore: Symbol("ContestStore"),
	GameBarController: Symbol("GameBarController"),
	ContestItemController: Symbol("ContestItemController"),
	ContestCountdownController: Symbol("ContestCountdownController"),
	ContestLockStatusController: Symbol("ContestLockStatusController"),
	HocSliderController: Symbol("HocSliderController"),
	ContestResultListController: Symbol("ContestResultListController"),
	UserStatsStore: Symbol("UserStatsStore"),
	UserStatsProvider: Symbol("UserStatsProvider"),
	LeaderboardController: Symbol("LeaderboardController"),
	OverallLeaderboardController: Symbol("OverallLeaderboardController"),
	ContestLeaderboardController: Symbol("ContestLeaderboardController"),
	LeaderboardStore: Symbol("LeaderboardStore"),
	LeaderboardProvider: Symbol("LeaderboardProvider"),
	ModalsStore: Symbol("ModalsStore"),
	ModalsController: Symbol("ModalsController"),
	ModalUsernameController: Symbol("ModalUsernameController"),
	ModalTermsController: Symbol("ModalTermsController"),
	ModalErrorController: Symbol("ModalErrorController"),
	LandingController: Symbol("LandingController"),
	LoginController: Symbol("LoginController"),
	RacingPicksController: Symbol("RacingPicksController"),
	GlobalLeaderboardController: Symbol("GlobalLeaderboardController"),
	ContestLeaderboardStore: Symbol("ContestLeaderboardStore"),
	AdvertisementStore: Symbol("AdvertisementStore"),
	AdvertisementController: Symbol("AdvertisementController"),
	AdvertisementApiProvider: Symbol("AdvertisementApiProvider"),
};
