import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";
import {useNavigate, useLocation} from "react-router-dom";
import {get} from "lodash";
import type {IModalsStore} from "data/stores/modals/modal.store";
import {ModalType, RequestState, SportParam} from "data/enums";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IUserStatsStore} from "data/stores/user_stats/user_stats.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IInit {
	navigate: ReturnType<typeof useNavigate>;
	location: ReturnType<typeof useLocation>;
}

export interface ITermsController extends ViewController<IInit> {
	get isLoading(): boolean;
	get isNRLLocation(): boolean;
	get isAFLLocation(): boolean;
	get isHRLocation(): boolean;
	get hasNRLTerms(): boolean;
	get hasAFLTerms(): boolean;
	get hasHRTerms(): boolean;
	get notAlllowedToAccessNRL(): boolean;
	get notAlllowedToAccessAFL(): boolean;
	get notAlllowedToAccessHR(): boolean;
	get notAlllowedToAccessGH(): boolean;

	goToLeague(): void;
}

@injectable()
export class TermsController implements ITermsController {
	private _navigate!: IInit["navigate"];
	private _location!: IInit["location"];
	@observable private _contestState: RequestState = RequestState.IDLE;
	@observable private _initFetchData: boolean = false;
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStatsStore) private _userStatsStore: IUserStatsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return this._contestState === RequestState.PENDING;
	}

	get hasNRLTerms() {
		const userTerms: string[] = get(this._userStore.user, "acceptTerms", []);
		return userTerms.includes("rugby_league");
	}

	get isNRLLocation() {
		return this._location?.pathname.includes("sport/nrl");
	}

	get notAlllowedToAccessNRL() {
		return !this.hasNRLTerms;
	}

	get hasAFLTerms() {
		const userTerms: string[] = get(this._userStore.user, "acceptTerms", []);
		return userTerms.includes("afl_football");
	}

	get isAFLLocation() {
		return this._location?.pathname.includes("sport/afl");
	}

	get notAlllowedToAccessAFL() {
		return !this.hasAFLTerms;
	}

	get hasHRTerms() {
		const userTerms: string[] = get(this._userStore.user, "acceptTerms", []);
		return userTerms.includes("horse_racing");
	}

	get isHRLocation() {
		return this._location?.pathname.includes("sport/hr");
	}

	get notAlllowedToAccessHR() {
		return !this.hasHRTerms;
	}
	get hasGHTerms() {
		const userTerms: string[] = get(this._userStore.user, "acceptTerms", []);
		return userTerms.includes("greyhound");
	}

	get isGHLocation() {
		return this._location?.pathname.includes("sport/gh");
	}

	get notAlllowedToAccessGH() {
		return !this.hasGHTerms;
	}

	get notAllowedToAccessAllSports() {
		return (
			this.notAlllowedToAccessGH ||
			this.notAlllowedToAccessHR ||
			this.notAlllowedToAccessAFL ||
			this.notAlllowedToAccessNRL
		);
	}

	public goToLeague() {
		this._navigate(`/leagues`);
	}

	init(params: IInit) {
		this._navigate = params?.navigate;
		this._location = params?.location;
		if (this.notAllowedToAccessAllSports) {
			this._modalsStore.showModal(ModalType.TERMS, {
				message: "tab_predictor",
				payload: "",
			});
		}
		void this.fetchData();
	}

	private async fetchData() {
		if (!this._initFetchData) {
			try {
				this._initFetchData = true;
				this._contestState = RequestState.PENDING;
				await Promise.all([
					this._userStatsStore.fetch(this.i18n.lang),
					//to do: refactor this: get four types of sports since there is no a complete api
					this._contestStore.getCompletedList("nrl"),
					this._contestStore.getCompletedList("afl"),
					// this._contestStore.getCompletedList("gh"),
					// this._contestStore.getCompletedList("hr"),
					// this._contestStore.getCompletedList("nba"),
					// this._contestStore.getCompletedList("soo"),
					// this._contestStore.getCompletedList("games"),
					this._contestStore.getActiveList("nrl" as SportParam),
					this._contestStore.getActiveList("afl" as SportParam),
					this._contestStore.getActiveList("gh" as SportParam),
					this._contestStore.getActiveList("hr" as SportParam),
					this._contestStore.getActiveList("nba" as SportParam),
					this._contestStore.getActiveList("soo" as SportParam),
					this._contestStore.getActiveList("games" as SportParam),
				]);
				this._contestState = RequestState.SUCCESS;
			} catch (e) {
				console.error("Error while fetching contest");
				this._contestState = RequestState.ERROR;
			}
		}
	}

	dispose(): void {
		return;
	}
}
