import "reflect-metadata";

import "assets/css/reset.css";
import "assets/fonts/stylesheet.css";
import "assets/css/core.css";

import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes, SecretGateRoutes} from "routes";
import {init, Integrations, BrowserTracing, Replay} from "@sentry/react";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	DIContainer,
	services,
	providers,
	stores,
	controllers,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {SENTRY_DSN_URL, BASE_URL} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {Session} from "views/components/session/session.component";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {ThemeProvider, StyledEngineProvider} from "@mui/material";
import {theme} from "assets/theming/theme";
import {Modals} from "views/components/modals/modals.component";
import {SecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";

if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Replay(),
			new Integrations.Breadcrumbs({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: ["*.f2p.media.geniussports.com", "tabxpickandwin.tab.com.au"],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);
createRoot(root).render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<InjectionProvider container={DIContainer}>
					<Bootstrap>
						<BrowserRouter basename={BASE_URL}>
							<Suspense fallback={<PagePreloader />}>
								{SecretGateController.IS_SECRET_PASSED ? (
									<Session>
										<RootRoutes />
									</Session>
								) : (
									<SecretGateRoutes />
								)}
							</Suspense>
							<Modals />
						</BrowserRouter>
					</Bootstrap>
				</InjectionProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
