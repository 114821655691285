import {inject, injectable} from "inversify";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {Language} from "data/enums";
import {IAdvertisementItem} from "data/stores/advertisement/advertisement.store";

export interface IAdsRequestPayload {
	lang: Language;
}

export interface IAdsResponse {
	items: IAdvertisementItem[];
}

export interface IAdvertisementApiProvider {
	getAds: ({lang}: IAdsRequestPayload) => Promise<AxiosResponse<IApiResponse<IAdsResponse>>>;
}

@injectable()
export class AdvertisementApiProvider implements IAdvertisementApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	getAds = ({lang}: IAdsRequestPayload) => {
		return this._http.get<IApiResponse<IAdsResponse>>(`${lang}/ads`);
	};
}
