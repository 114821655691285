import {injectable, inject} from "inversify";
import {AxiosResponse} from "axios";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboard, IGetLeaderboard, IGetContestLeaderboard} from "data/types/leaderboard";
import type {IJSONProvider} from "data/providers/json/json.provider";

export interface ILeaderboardProvider {
	getOverall: (params: IGetLeaderboard) => Promise<AxiosResponse<IApiResponse<ILeaderboard>>>;
	getContest: (params: IGetLeaderboard) => Promise<AxiosResponse<IApiResponse<ILeaderboard>>>;
	getOverallLeaderBoard: (
		params: IGetLeaderboard
	) => Promise<AxiosResponse<IApiResponse<ILeaderboard>>>;
	getGlobalLeaderBoard: (
		params: IGetLeaderboard
	) => Promise<AxiosResponse<IApiResponse<ILeaderboard>>>;
	getWeeklyContestLeaderBoard: (
		params: IGetContestLeaderboard
	) => Promise<AxiosResponse<IApiResponse<ILeaderboard>>>;
}

@injectable()
export class LeaderboardProvider implements ILeaderboardProvider {
	constructor(
		@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider
	) {}

	getOverall = ({lang, contestId, ...params}: IGetLeaderboard) =>
		this._http.get<IApiResponse<ILeaderboard>>(
			`${lang}/predictor/ranking/overall-leaderboard/${contestId || 0}`,
			params
		);

	getGlobalLeaderBoard = ({lang, ...params}: IGetLeaderboard) =>
		this._http.get<IApiResponse<ILeaderboard>>(
			`${lang}/predictor/ranking/global-leaderboard`,
			params
		);

	getOverallLeaderBoard = ({lang, competitionId, ...params}: IGetLeaderboard) =>
		this._http.get<IApiResponse<ILeaderboard>>(
			`${lang}/predictor/ranking/overall-leaderboard/${competitionId || 0}`,
			params
		);

	getContest = ({lang, contestId, ...params}: IGetLeaderboard) =>
		this._http.get<IApiResponse<ILeaderboard>>(
			`${lang}/predictor/ranking/contest-leaderboard/${contestId || 0}`,
			params
		);
	getWeeklyContestLeaderBoard = ({lang, contestName, week, ...params}: IGetContestLeaderboard) =>
		this._http.get<IApiResponse<ILeaderboard>>(
			`${lang}/predictor/ranking/contest-leaderboard/${contestName}/week/${week}`,
			params
		);
}
