import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {AdvertisementPlace, Language, RequestState} from "data/enums";
import {isEmpty, keyBy} from "lodash";
import {Bindings} from "data/constants/bindings";
import type {IAdvertisementApiProvider} from "data/providers/api/advertisement.provider";

export interface IAdvertisementItem {
	name: AdvertisementPlace;
	desktopImage?: string;
	mobileImage?: string;
	link: string;
}

type IAdvertisements = {
	[key in AdvertisementPlace]?: IAdvertisementItem;
};

export interface IAdvertisementStore {
	get advertisements(): IAdvertisements;

	fetchAdvertisementBanners(): Promise<void>;
}

@injectable()
export class AdvertisementStore implements IAdvertisementStore {
	@observable private _advertisements: IAdvertisementItem[] = [];
	@observable private _requestState = RequestState.IDLE;

	@computed get advertisements() {
		return keyBy(this._advertisements, "name");
	}

	constructor(
		@inject(Bindings.AdvertisementApiProvider)
		private _advertisementApiProvider: IAdvertisementApiProvider
	) {
		makeAutoObservable(this);
	}

	@action
	async fetchAdvertisementBanners() {
		if (!isEmpty(this._advertisements) || this._requestState === RequestState.PENDING) {
			return;
		}
		this._requestState = RequestState.PENDING;
		await this._advertisementApiProvider
			.getAds({lang: Language.EN})

			.then((result) =>
				runInAction(() => {
					console.log("result", result);
					this._advertisements = result.data.success.items;
					this._requestState = RequestState.IDLE;
				})
			)
			.catch(() => {
				runInAction(() => {
					this._requestState = RequestState.IDLE;
				});
			});
	}
}
