import styled from "@emotion/styled";
import React from "react";
import {ReactComponent as WereOn} from "assets/img/TAB_white_footer_logo.svg";
import {Typography} from "@mui/material";
import {ReactComponent as GeniusSportsLogo} from "assets/img/white_genius_sports.svg";
import {IS_SECRET_ENABLED, IS_SECRET_PASSED} from "data/constants";
import {Exist} from "views/components/exist/exist.component";

const mobileViewQuery = `max-width: 640px`;

const GeniusLogo = styled(GeniusSportsLogo)`
	color: currentColor;
`;

const Div = styled.footer`
	border-top: 1px solid var(--TAB-Masterbrand-Primary-TAB-Neon-60);
	width: 100%;
	margin: 0 auto;
	padding-top: 24px;
	bottom: 0;
	background: var(--TAB-Masterbrand-Secondary-TAB-Night-80);
	color: var(--tabx-neutrals-off-white);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 21px;
	@media (max-width: 1180px) {
		padding: 24px 20px 0px 20px;
	}
`;

export const ExternalLink = styled.a``;
const FooterMenuWrapper = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 14px;
	color: var(--TAB-Masterbrand-Primary-TAB-Wattle-60);
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	height: 30px;
`;

const Sponsor = styled(ExternalLink)`
	color: var(--tabx-neutrals-off-white);
	display: inline-flex;
	gap: 8px;
	align-items: center;
	background: none;
	transition: opacity 0.5s;
	margin-right: 24px;
	&:hover {
		opacity: 0.5;
	}
	@media (${mobileViewQuery}) {
		margin-top: 24px;
		margin-bottom: 24px;
	}
`;

const Copyright = styled.span`
	font-size: 12px;
	text-transform: uppercase;
	margin-bottom: 10px;
	text-align: center;
`;

const Disclaimer = styled.span`
	font-size: 12px;
	text-transform: uppercase;
	text-align: center;
`;

const Span = styled(Typography)`
	text-align: center;
	font-size: 12px;
	font-weight: 400;
	line-height: 120%; /* 19.2px */
	color: var(--tabx-neutrals-off-black);
	background-color: white;
`;

const BoldSpan = styled(Span)`
	font-family: Arial;
	font-size: 25px;
	font-weight: 800;

	@media (${mobileViewQuery}) {
		font-size: 22px;
		font-weight: 600;
	}
`;

const FooterResponsibleWrap = styled.div`
	padding: 10px;
	background-color: white;
	border-radius: 5px;
`;
export const Footer: React.FC = () => {
	let count = 0;

	const handleSecretClick = () => {
		if (!IS_SECRET_PASSED && IS_SECRET_ENABLED) {
			count++;
			if (count >= 10) {
				sessionStorage.setItem("isSecretPassed", "true");
				window.location.reload();
			}
		}
	};

	return (
		<Div>
			<WereOn onClick={handleSecretClick} />
			<FooterMenuWrapper>
				<Exist when={IS_SECRET_PASSED}>
					<ExternalLink href="https://tabxpickandwin.tab.com.au/help" target="_blank">
						Terms & Conditions |
					</ExternalLink>
					{"\u00A0"}
				</Exist>
				<ExternalLink href="https://help.tab.com.au/s/" target="_blank">
					Contact Us
				</ExternalLink>
			</FooterMenuWrapper>
			<Wrapper>
				<Sponsor href="https://geniussports.com/" target="_blank">
					<Typography variant="body3">Powered by:</Typography>
					<GeniusLogo />
				</Sponsor>
			</Wrapper>
			<FooterResponsibleWrap>
				<BoldSpan>
					CHANCES ARE YOU'RE <br />
					ABOUT TO LOSE.
				</BoldSpan>
				<Span>
					For free and confidential support call 1800 858 858 <br />
					or visit{" "}
					<ExternalLink href="https://gamblinghelponline.org.au" target="_blank">
						gamblinghelponline.org.au
					</ExternalLink>
					.
				</Span>
			</FooterResponsibleWrap>
			<Disclaimer></Disclaimer>
			<Copyright>&#9400; TAB PICK & WIN 2025</Copyright>
		</Div>
	);
};
