import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {WithFooterLayout} from "views/components/layout/layout.components";
import {CheckTerms} from "views/components/route/check_terms_route.component";

const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const Questions = lazy(retryFailLoad(() => import("views/pages/questions/questions.page")));
const Rankings = lazy(retryFailLoad(() => import("views/pages/rankings/rankings.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ContestResultListPage = lazy(
	retryFailLoad(() => import("views/pages/contest_result_list/contest_result_list.page"))
);
// const ComponentsReview = lazy(
// 	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
// );
const LoginForm = lazy(retryFailLoad(() => import("views/pages/login/login.page")));
//const OffSeason = lazy(retryFailLoad(() => import("views/pages/off_season/off_season.page")));
const MyPicks = lazy(retryFailLoad(() => import("views/pages/picks/picks.page")));
const RacingExtra = lazy(retryFailLoad(() => import("views/pages/racing_extra/racing_picks.page")));
const GamesPage = lazy(retryFailLoad(() => import("views/pages/games/games.page")));

const SportRoutes = () => {
	const CommonRoutes = () => (
		<Routes>
			<Route path="contest/:id" element={<Questions />} />
			<Route path="contest/:id/:questionId" element={<Questions />} />
			<Route path="racing/:id" element={<RacingExtra />} />
			<Route path="contest/result/:contestId" element={<ContestResultListPage />} />
			<Route path="event/:id" element={<GamesPage />} />
		</Routes>
	);

	// if (game === "gh") {
	// 	return SecretGateController.IS_SECRET_PASSED ? (
	// 		<Routes>
	// 			<Route path="contest/:id" element={<Questions />} />
	// 			<Route path="contest/:id/:questionId" element={<Questions />} />
	// 			<Route path="racing/:id" element={<RacingExtra />} />
	// 			<Route path="contest/result/:contestId" element={<ContestResultListPage />} />
	// 		</Routes>
	// 	) : (
	// 		<Routes>
	// 			<Route path="/*" element={<MyPicks />} />
	// 		</Routes>
	// 	);
	// }
	// if (game  === "games") {
	// 	return SecretGateController.IS_SECRET_PASSED ? (
	// 		<Routes>
	// 			<Route path="event/:id" element={<GamesPage />} />
	// 		</Routes>
	// 	) : (
	// 		<Routes>
	// 			<Route path="/*" element={<MyPicks />} />
	// 		</Routes>
	// 	);
	// }
	return <CommonRoutes />;
};

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route element={<PrivateRoute />}>
			<Route element={<WithFooterLayout />}>
				<Route element={<CheckTerms />}>
					<Route index path="landing" element={<MyPicks />} />
					<Route path="sport/:game/*" element={<SportRoutes />} />
					{/* <Route path="games/:id" element={<GamesPage />} /> */}
					<Route path="leaderboard" element={<Rankings />} />
				</Route>
				<Route path="/help/*" element={<Help />} />
				<Route path="/prizes" element={<Help />} />
				<Route path="my-account" element={<MyAccount />} />
			</Route>
		</Route>
		<Route element={<NotAuthOnlyRoute />}>
			<Route element={<WithFooterLayout />}>
				<Route index element={<LoginForm />} />
			</Route>
		</Route>

		<Route element={<WithFooterLayout />}>
			<Route path="*" element={<NotFound />} />
		</Route>
	</Routes>
);

export default RootRoutes;
