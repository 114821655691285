import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {INavigationRoute} from "data/types/navigation";
import type {IUserStore} from "data/stores/user/user.store";
import {useNavigate} from "react-router-dom";
import {ICompetition} from "data/types/competitions";

interface IParams {
	sport?: string | undefined;
	navigate: ReturnType<typeof useNavigate>;
}

interface ISport {
	[key: string]: boolean;
}
export interface IHeaderController extends ViewController<IParams> {
	toggleMenu: () => void;

	get isLoggedIn(): boolean;

	get routes(): INavigationRoute[];

	get mobileRoutes(): INavigationRoute[];

	get menuState(): boolean;

	set menuState(value: boolean);

	get expandableState(): ISport;
	toggleSubMenu: (sport: string) => void;

	emulateLogin: () => void;

	updateSport: (sport: string | undefined) => void;
	get controllerSport(): string | undefined;

	get isMenuOpen(): boolean;
	handleToggleMenu: (isOpen?: boolean) => void;
	checkBeforeNavigate: (competition: ICompetition) => void;
}

@injectable()
export class HeaderController implements IHeaderController {
	private _navigate: IParams["navigate"] | null = null;
	@observable private _isOpenMobileMenu = false;
	private _sport: IParams["sport"] = "/";
	private _isMenuOpen = false;
	@observable private _expandableMenu: ISport = {AFL: true};

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isMenuOpen() {
		return this._isMenuOpen;
	}

	get isLoggedIn() {
		return this._userStore.isAuthorized;
	}

	get controllerSport() {
		return this._sport;
	}

	get routes(): INavigationRoute[] {
		return [
			{
				label: this.i18n.t("navigation.mypicks", "My Picks"),
				path: "/landing",
			},
			{
				label: this.i18n.t("navigation.leaderboard", "Leaderboard"),
				path: "/leaderboard",
			},
			{
				label: this.i18n.t("navigation.prizes", "Prizes"),
				path: `/prizes`,
			},
			{
				label: this.i18n.t("navigation.help", "Help"),
				path: `/help/game-guidelines`,
			},
			// {
			// 	label: this.i18n.t("navigation.myaccount", "My Account"),
			// 	path: `/my-account`,
			// },
		];
	}

	get mobileRoutes(): INavigationRoute[] {
		return [
			// {
			// 	label: this.i18n.t("navigation.home", "Home"),
			// 	path: "/landing",
			// },
			{
				label: this.i18n.t("navigation.mypicks", "My Picks"),
				path: "",
			},
			{
				label: this.i18n.t("navigation.leaderboard", "Leaderboard"),
				path: `leaderboard`,
			},
			{
				label: this.i18n.t("navigation.prizes", "Prizes"),
				path: `prizes`,
			},
			{
				label: this.i18n.t("navigation.help", "Help"),
				path: `help/game-guidelines`,
			},
		];
	}

	get menuState() {
		return this._isOpenMobileMenu;
	}

	set menuState(value: boolean) {
		this._isOpenMobileMenu = value;
	}

	get expandableState() {
		return this._expandableMenu;
	}
	toggleSubMenu = (sport: string) => {
		this._expandableMenu = {[sport]: !this._expandableMenu[sport]};
	};
	toggleMenu = () => {
		this.menuState = !this.menuState;
	};

	updateSport = (sport: string | undefined) => {
		this._sport = sport;
	};

	@action handleToggleMenu = (isOpen?: boolean) => {
		this._isMenuOpen = isOpen === undefined ? !this._isMenuOpen : isOpen;
		document.body.style.overflow = this._isMenuOpen ? "hidden" : "initial";
	};

	checkBeforeNavigate = (competetion: ICompetition) => {
		const check = this._userStore.user?.acceptTerms.includes(competetion.sport);
		if (check) {
			this._navigate?.(`/sport/${competetion.name.toLowerCase()}`);
		} else {
			this._navigate?.(`/landing`);
		}
	};

	dispose(): void {
		return;
	}

	emulateLogin = () => {
		this._userStore.emulateLogin();
	};

	init(params: IParams): void {
		// add pathname to this, use logic from src/views/pages/help/help.controller.ts to set pathname correctly
		this._sport = params.sport;
		this._navigate = params.navigate;
		this._expandableMenu = {[params.sport?.toLocaleUpperCase() || ""]: true};
	}
}
