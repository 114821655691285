export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const STATIC_CONTENT_JSON_URL = process.env.REACT_APP_STATIC_CONTENT_JSON_URL || "";
export const DEFAULT_LIST_SIZE = (process.env.REACT_APP_DEFAULT_LIST_SIZE || 20) as number;
export const ENABLE_ADS = Boolean(JSON.parse(process.env.REACT_APP_ENABLE_ADS || "false"));
export const DISABLE_LEADERBOARD = Boolean(
	JSON.parse(process.env.REACT_APP_DISABLE_LEADERBOARD || "false")
);

export const DISABLED_SPORTS = process.env.REACT_APP_HIDE_SPORTS?.split(",") || "";

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const PUBLIC_URL =
	process.env.REACT_SITE_URL ||
	"https://tab-x-predictor-challenge.uat.f2p.media.geniussports.com/";

const isSecretePassed = sessionStorage.getItem("isSecretPassed");
const IS_SECRET_ENABLE = true;
export const IS_SECRET_PASSED = isSecretePassed
	? JSON.parse(isSecretePassed) === true
	: !IS_SECRET_ENABLE;
export const IS_SECRET_ENABLED = (process.env.REACT_APP_IS_SECRET_ENABLED || "") === "true";

export const USE_OVERALL_LEADERBOARD = Boolean(
	JSON.parse(process.env.REACT_APP_USE_OVERALL_LEADERBOARD || "false")
);

export const SELECTED_USE_OVERALL_LEADERBOARD = Boolean(
	JSON.parse(process.env.REACT_APP_SELECTED_USE_OVERALL_LEADERBOARD || "false")
);

export const SHOW_SEASONS_LEADERBOARD = Boolean(
	JSON.parse(process.env.REACT_APP_SHOW_SEASON_LEADERBOARD || "false")
);

export const SELECTED_TO_USE_OVERALL_LEADERBOARD = SELECTED_USE_OVERALL_LEADERBOARD
	? ["NBA", "SOO", "GAMES", "AFL", "NRL", "GH", "HR"]
	: ["NBA", "SOO", "GAMES"];
