import {inject, injectable} from "inversify";

import {action, makeAutoObservable, observable} from "mobx";
import {AdvertisementPlace} from "data/enums";
import {ViewController} from "data/types/structure";
import type {
	IAdvertisementItem,
	IAdvertisementStore,
} from "data/stores/advertisement/advertisement.store";
import {Bindings} from "data/constants/bindings";
import {PUBLIC_URL} from "data/constants";

interface IProps {
	isMobile: boolean;
}

export interface IAdvertisementController extends ViewController<IProps> {
	get advertisement(): IAdvertisementItem | undefined;

	get imageURL(): string | undefined;

	get imageDesktopURL(): string | undefined;

	get link(): string | undefined;
}

@injectable()
export class AdvertisementController implements IAdvertisementController {
	constructor(
		@inject(Bindings.AdvertisementStore) private _advertisementStore: IAdvertisementStore
	) {
		makeAutoObservable(this);
	}

	@observable _isMobile?: boolean = false;

	get advertisement() {
		return this._advertisementStore.advertisements[AdvertisementPlace.MainBottom];
	}

	get imageDesktopURL() {
		if (!this.advertisement?.desktopImage) return undefined;

		const imageURL = new URL(PUBLIC_URL);
		imageURL.pathname = this.advertisement.desktopImage;

		return imageURL.toString();
	}

	get imageMobileURL() {
		if (!this.advertisement?.mobileImage) return undefined;

		const imageURL = new URL(PUBLIC_URL);
		imageURL.pathname = this.advertisement.mobileImage;

		return imageURL.toString();
	}

	get imageURL() {
		return this._isMobile ? this.imageMobileURL : this.imageDesktopURL;
	}

	get link() {
		return this.advertisement?.link;
	}

	@action init({isMobile}: IProps) {
		this._isMobile = isMobile;
		void this._advertisementStore.fetchAdvertisementBanners();
	}

	@action onChange({isMobile}: IProps) {
		this._isMobile = isMobile;
	}
}
